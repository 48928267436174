import { PersonRounded, MenuRounded } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import toast from "react-hot-toast";

const AdminHeader = ({ siebarToggle, windowWidth }) => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);

  // console.log(user);

  const handleLogout = (e) => {
    setUser("");
    navigate("/");
    toast.remove();
    toast.success("You have been logged out!");
    sessionStorage.clear();
  };

  return (
    <div className="w-full h-14 bg-white sticky top-0 z-20 shadow-md">
      <div className="h-full px-5 py-0 flex items-center justify-between">
        {windowWidth < 768 && (
          <button onClick={siebarToggle}>
            <MenuRounded />
          </button>
        )}
        {windowWidth > 768 && (
          <div className="h-full flex items-center justify-center">
            <h1 className="font-bold text-2xl text-orangeBg">
              <img src="/logo.png" className="w-10 h-10" />
            </h1>
          </div>
        )}
        <div className="flex items-center">
          <div className="flex mr-4 items-center gap-1">
            <PersonRounded className="text-orangeBg rounded mr-2" />
            <span>
              {user?.lName} {user?.fName}
            </span>
          </div>
          <button
            onClick={handleLogout}
            className="bg-darkBlue uppercase px-6 py-2 text-white text-sm"
          >
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
