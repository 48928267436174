import React from "react";

const ArrivalForm = () => {
  return (
    <>
      <h1>Arrival Form</h1>
    </>
  );
};

export default ArrivalForm;
