import React, { useState } from "react";
import { ArrowBack } from "@material-ui/icons";
import useWindowDimensions from "../../../hooks/useWindowSize";
import { useNavigate, useParams } from "react-router-dom";
import { capitalizeText } from "../../../helpers/capitalizeText";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { api } from "../../../api";
import { staff } from "../../../api/staff";
import VerticalMenu from "../../../components/VerticalMenu/VerticalMenu";
import { vertButtonClass, backBtnClass } from "../Style";
import CenteredModal from "../../../components/Modal/CenteredModal";
import ViewStaffPermission from "./ViewStaffPermission";
import { Close } from "@material-ui/icons";
import Loader from "../../../components/Loader/Loader";
import { convertDate } from "../../../helpers/convertDate";
import PermissionGuard, {
  JointPermissionGuard,
} from "../../../components/PermissionGuard/PermissionGuard";

const StaffInfo = ({ refetch, permissions }) => {
  const { id } = useParams();

  const { width } = useWindowDimensions();
  const user = JSON.parse(sessionStorage.getItem("user"));
  // const currentStaff = JSON.parse(sessionStorage.getItem("currentStaff"));
  const { mutateAsync: UpdateStaff } = useMutation(staff.updateStaff);
  const { mutateAsync: DeactivateStaff } = useMutation(staff.updateStatus);

  const {
    data: currentStaff,
    refetch: refetchStaff,
    isLoading,
  } = useQuery(
    ["Current Staff", id],
    async () => {
      const data = await staff.getCurrentStaff(id);
      return data;
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: true,
      select: (data) => data?.message,
    }
  );

  const { data: accountSummary } = useQuery(
    ["Account Summary", id],
    async () => {
      const data = await staff.getAccountSummary(id);
      return data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data?.data,
    }
  );

  // console.log(accountSummary);

  // console.log(currentStaff);

  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  // console.log({ currentStaff });

  const handleViewStaffs = () => {
    navigate(`/${user?._id}/manage-staff/staffs`);
  };

  const [modal, showModal] = useState(false);

  const handleModal = () => {
    showModal(!modal);
  };

  const handleUpdate = async (values) => {
    toast.loading("Processing...");

    const payload = {
      ...values,
      permissions: currentStaff?.permissions,
    };

    // console.log(payload);

    try {
      const data = await UpdateStaff({ id, payload });
      // console.log(data);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        navigate(-1);
      }, 1000);
      refetch();
    } catch ({ response }) {
      const { data } = response;
      toast.remove();
      toast.error(data?.message);
    }
  };

  const handleStatus = async () => {
    toast.loading("Processing...");
    if (
      confirm(
        `Are you sure you want to ${
          currentStaff?.status === "active" ? "de-activate" : "activate"
        } ${currentStaff?.fName} ${currentStaff?.lName}?`
      )
    ) {
      try {
        const data = await DeactivateStaff({
          id,
          status: currentStaff?.status === "active" ? "no" : "yes",
        });
        // console.log({ data });
        toast.remove();
        toast.success(data?.message);
        refetch();
        setTimeout(() => {
          location.reload();
        }, 1000);
      } catch ({ response }) {
        // console.log({ response });
        toast.remove();
        toast.error(response?.data?.message);
      }
    }
  };

  // console.log(permissions);

  if (isLoading) {
    return (
      <React.Fragment>
        <div className="flex items-center justify-between w-full px-5">
          <button className={backBtnClass} onClick={handleViewStaffs}>
            <ArrowBack className="text-sm" />
          </button>
          <JointPermissionGuard
            requiredPermissions={["deleteUsers", "editUsers"]}
          >
            <VerticalMenu isBackground>
              <div className="">
                <button className={vertButtonClass} onClick={handleStatus}>
                  {currentStaff?.status === "active"
                    ? "Deactivate Staff"
                    : "Activate Staff"}
                </button>
                <button className={vertButtonClass} onClick={handleModal}>
                  View Permissions
                </button>
              </div>
            </VerticalMenu>
          </JointPermissionGuard>
        </div>
        <Loader />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="flex items-center justify-between w-full px-5">
        <button className={backBtnClass} onClick={handleViewStaffs}>
          <ArrowBack className="text-sm" />
        </button>
        <VerticalMenu isBackground>
          <div className="">
            <button className={vertButtonClass} onClick={handleStatus}>
              {currentStaff?.status === "active"
                ? "Deactivate Staff"
                : "Activate Staff"}
            </button>
            <button className={vertButtonClass} onClick={handleModal}>
              View Permissions
            </button>
          </div>
        </VerticalMenu>
      </div>
      <form
        className="p-3 grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-4 border border-slate-300"
        onSubmit={handleSubmit(handleUpdate)}
      >
        <div className="flex items-start flex-col justify-center gap-1">
          <p>Surname</p>
          <input
            className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:px-5 focus:border-orangeBg focus:bg-white"
            text="text"
            {...register("lName", {
              required: true,
            })}
            placeholder={currentStaff?.lName}
            defaultValue={currentStaff?.lName}
          />
        </div>
        <div className="flex items-start flex-col justify-center gap-1">
          <p>First Name</p>
          <input
            className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:px-5 focus:border-orangeBg focus:bg-white"
            disabled
            text="text"
            placeholder={currentStaff?.fName}
          />
        </div>
        <div className="flex items-start flex-col justify-center gap-1">
          <p>Email Address</p>
          <input
            className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none focus:ring-0 bg-slate-100 duration-200 focus:px-5 focus:border-orangeBg focus:bg-white"
            type="email"
            disabled
            placeholder={currentStaff?.email}
            // defaultValue={currentStaff?.email}
          />
        </div>
        <div className="flex items-start flex-col justify-center gap-1">
          <p>Phone Number</p>
          <input
            className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none focus:ring-0 bg-slate-100 duration-200 focus:px-5 focus:border-orangeBg focus:bg-white"
            type="text"
            disabled
            placeholder={currentStaff?.phone}
          />
        </div>
        <div className="flex items-start flex-col justify-center gap-1">
          <p>Status</p>
          <input
            className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:px-5 focus:border-orangeBg focus:bg-white"
            disabled
            text="text"
            placeholder={capitalizeText(currentStaff?.status)}
          />
        </div>
        <div className="flex-1 flex items-end">
          <PermissionGuard requiredPermissions={["editUsers"]}>
            <button
              type="submit"
              className="px-5 py-2 border border-orangeBg bg-orangeBg font-medium text-white rounded duration-100 hover:text-darkBlue hover:bg-orange-200 hover:border-orange-200"
            >
              UPDATE INFO
            </button>
          </PermissionGuard>
        </div>
      </form>

      {/* Account summary */}

      <h1 className="text-darkBlue mt-10 mb-2 font-semibold">
        ACCOUNT SUMMARY
      </h1>
      <div className={width < 601 ? "overflow-x-scroll" : ""}>
        <table
          className="w-full border border-slate-300"
          style={{ minWidth: "600px" }}
        >
          <thead className="bg-darkBlue text-white">
            <tr>
              <th className="border border-darkBlue py-3 px-2">NAME</th>
              <th className="border border-darkBlue py-3 px-2">UPDATE</th>
              <th className="border border-darkBlue py-3 px-2">DATE</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {accountSummary?.map((data) => (
              <tr
                key={data?._id}
                className="border border-slate-300 duration-200 bg-slate-100"
              >
                <td className="py-3 px-2">
                  {data?.fName} {data?.lName}
                </td>
                <td className="py-3 px-2">{data?.action}</td>
                <td className="py-3 px-2">{convertDate(data?.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CenteredModal
        title={"Permissions"}
        open={modal}
        setOpen={handleModal}
        closeButtonLabel={<Close />}
      >
        <ViewStaffPermission
          handleModal={handleModal}
          refetch={refetchStaff}
          permissions={permissions}
          userPermissions={currentStaff?.permissions}
        />
      </CenteredModal>
    </React.Fragment>
  );
};

export default StaffInfo;
