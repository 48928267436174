import React, { useState } from "react";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { caroptions } from "../../../api/caroptions";
import Select from "react-select";

const AddModel = ({ data }) => {
  const { register, handleSubmit } = useForm();

  const [currentMake, setCurrentMake] = useState(null);

  //   console.log(data);

  const formattedData = data?.map((item) => {
    return { label: item?.make, value: item?._id };
  });

  //   console.log(formattedData);

  const { mutateAsync } = useMutation(caroptions.addCarModel);

  const onSubmit = async (payload) => {
    toast.loading("Processing...");

    try {
      const data = await mutateAsync({ id: currentMake.value, payload });
      //   console.log(data);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        location.reload();
      }, 1000);
    } catch ({ response }) {
      // console.log({ response });
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  //   console.log(currentMake);

  return (
    <div className="py-4 min-h-[250px]">
      <p className="w-full text-left">Select car make</p>
      <Select
        placeholder="Car Make..."
        className="mb-3 mt-1"
        onChange={(selected) => setCurrentMake(selected)}
        options={formattedData}
      />
      {currentMake && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full flex items-center justify-center gap-3">
            <div className="flex-1 flex items-start flex-col justify-center gap-1">
              <p>Enter Car Model </p>
              <input
                className="w-full flex-1 border border-slate-300 block px-4 py-3 rounded-md outline-none  duration-200 focus:ring-0 focus:px-5 focus:border-darkBlue focus:bg-white"
                required
                type={"text"}
                {...register("carModel", {
                  required: true,
                })}
              />
            </div>
          </div>
          <div className="w-full flex items-center justify-start">
            <input
              type="submit"
              value="SUBMIT"
              className="mt-5 w-full px-5 py-2 border border-darkBlue cursor-pointer bg-darkBlue text-white rounded duration-100"
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default AddModel;
