import React, { useState } from "react";
import Search from "../../../components/Search/Search";
import Pagination from "../../../components/Pagination/Pagination";
import { longtermleasing as getLeasing } from "../../../api/longtermleasing";
import { useQuery } from "react-query";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";

const AcceptedLeasingList = ({ width }) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ["Accepted Leasing Items", { search, currentPage, perPage }],
    async ({ queryKey }) => {
      const data = await getLeasing.getAssignedLongTermLeasing(queryKey[1]);
      return data;
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  // console.log(data);

  const handleViewLeasingItem = (item) => {
    navigate(item._id);
  };

  return (
    <div className={`${width > 600 ? "w-3/4" : "w-full"}`}>
      <div className="mb-1">
        <Search setCurrentPage={setCurrentPage} setSearch={setSearch} />
      </div>
      <div className={`my-2 ${width < 601 ? "overflow-x-scroll" : ""}`}>
        <table
          className="w-full border border-slate-300"
          style={{ minWidth: "600px" }}
        >
          <thead className="text-white">
            <tr className="bg-darkBlue rounded-md">
              <th className="border border-darkBlue font-semibold py-3 px-2">
                NAME
              </th>
              <th className="border border-darkBlue font-semibold py-3 px-2">
                START DATE
              </th>
              <th className="border border-darkBlue font-semibold py-3 px-2">
                STATE
              </th>
              <th className="border border-darkBlue font-semibold py-3 px-2">
                CAR TYPE
              </th>
              <th className="border border-darkBlue font-semibold py-3 px-2">
                DURATION
              </th>
            </tr>
          </thead>
          <tbody className="text-center">
            {data?.data?.map((item, index) => (
              <tr
                className="border border-slate-300 flex-1 bg-slate-50 w-full duration-200 cursor-pointer hover:bg-slate-200"
                key={index}
                onClick={() => handleViewLeasingItem(item)}
              >
                <td className="py-3 px-2">
                  {item?.fName} {item?.lName}
                </td>
                <td className="py-3 px-2">{item?.startDate}</td>
                <td className="py-3 px-2">{item?.state}</td>
                <td className="py-3 px-2">
                  {item?.carType
                    ?.map((car) => `${car?.carType} (${car?.quantity})`)
                    .join(", ")}
                </td>
                <td className="py-3 px-2 capitalize">{item?.duration}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading && <Loader height={"100px"} />}
      </div>
      <Pagination
        data={data?.data_count}
        pageNo={currentPage}
        setPageNo={setCurrentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </div>
  );
};

export default AcceptedLeasingList;
