import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Box, Text, Image } from "@theme-ui/components";
import { ErrorOutlined, Close } from "@material-ui/icons";
import { vendors } from "../../../api/vendors";
import VerticalMenu from "../../../components/VerticalMenu/VerticalMenu";
import { vertButtonClass } from "../../ManageStaff/Style";
import Loader from "../../../components/Loader/Loader";
import Pagination from "../../../components/Pagination/Pagination";
import CenteredModal from "../../../components/Modal/CenteredModal";
import toast from "react-hot-toast";
import IssueDriverStrike from "./IssueDriverStrike";
import ActivateDeactivateDriver from "./ActivateDeactivateDriver";

const Drivers = () => {
  const { vendor_id: id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [currentDriver, setCurrentDriver] = useState({});

  const [modal, setModal] = useState(false);
  const [strikeModal, setStrikeModal] = useState(false);

  const { data: drivers, isLoading } = useQuery(
    ["selected Drivers", { id, currentPage, perPage }],
    ({ queryKey }) => vendors.getDrivers(queryKey[1]),
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  // console.log(drivers);

  const handleStrike = (driver) => {
    toast.loading("Processing...");
    setCurrentDriver(driver);
    setTimeout(() => {
      toast.remove();
      setStrikeModal(true);
    }, 1000);
  };

  const handleActivate = (driver) => {
    toast.loading("Processing...");
    setCurrentDriver(driver);
    setTimeout(() => {
      toast.remove();
      setModal(true);
    }, 1000);
  };

  if (drivers?.data?.length < 1) {
    return (
      <Box sx={{ width: "100%", textAlign: "center" }} mt={3}>
        <ErrorOutlined className="mr-1 text-orangeBg" />
        <Text>No data</Text>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <CenteredModal
        title={""}
        open={modal}
        setOpen={() => setModal(!modal)}
        closeButtonLabel={<Close />}
      >
        <ActivateDeactivateDriver
          currentDriver={currentDriver}
          handleModal={() => setModal(!modal)}
        />
      </CenteredModal>
      <CenteredModal
        title={""}
        open={strikeModal}
        setOpen={() => setStrikeModal(!strikeModal)}
        closeButtonLabel={<Close />}
      >
        <IssueDriverStrike
          currentDriver={currentDriver}
          handleModal={() => setStrikeModal(!strikeModal)}
        />
      </CenteredModal>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            marginTop: "10px",
          }}
          className="bg-slate-200 p-10 overflow-y-scroll border border-gray-400 gap-[20px] w-full max-h-[500px] grid grid-cols-1 md:grid-cols-3"
        >
          {drivers?.data?.map((driver) => (
            <Box
              key={driver._id}
              className=" flex flex-col p-3 gap-[10px] rounded-md border border-slate-100 shadow bg-white"
            >
              <Image
                sx={{ height: "150px", border: "1px dotted #ccc" }}
                className="object-contain"
                src={driver?.profilePicture?.url}
                variant="avatar"
                alt=""
              ></Image>
              <Text>
                {driver?.fName} {driver?.lName}
              </Text>
              <Text>{driver?.driversLicenseNumber}</Text>
              <div className="w-full flex items-center justify-between">
                <Text>Strikes: {driver?.numberOfStrikes}</Text>
                <VerticalMenu isBackground>
                  <div className="">
                    <button
                      onClick={() => handleStrike(driver)}
                      className={vertButtonClass}
                    >
                      Issue Strike
                    </button>
                    <button
                      onClick={() => handleActivate(driver)}
                      className={vertButtonClass}
                    >
                      {driver?.accountStatus === "yes"
                        ? "De-activate"
                        : "Activate"}
                    </button>
                  </div>
                </VerticalMenu>
              </div>
            </Box>
          ))}
        </Box>
      )}
      <Pagination
        data={drivers?.data_count}
        pageNo={currentPage}
        setPageNo={setCurrentPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </React.Fragment>
  );
};

export default Drivers;
