import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import bg1 from "../images/cardbase.png";
import toast from "react-hot-toast";
import "../screens/ManageStaff/ManageStaff.scss";
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import CenteredModal from "../components/Modal/CenteredModal";
import { Close } from "@material-ui/icons";
import PasswordReset from "./PasswordReset";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { auth } from "../api/auth";
import { useMutation } from "react-query";

export function SigninPage() {
  const { register, handleSubmit } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [passwordModal, setPasswordModal] = useState(false);
  const { mutateAsync: onLogin } = useMutation(auth.login);

  function greetTime() {
    const today = new Date();
    const hourOfDay = today.getHours(); //The current hour of today
    if (hourOfDay > 11 && hourOfDay <= 16) {
      return "afternoon";
    } else if (hourOfDay > 16) {
      return "evening";
    } else {
      return "morning";
    }
  }

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordModal = () => {
    setPasswordModal(!passwordModal);
  };

  const onSubmit = async (payload) => {
    try {
      toast.loading("Processing...");
      const data = await onLogin(payload);
      toast.remove();
      sessionStorage.setItem(
        "user",
        JSON.stringify({ ...data?.result, token: data?.token })
      );
      // console.log(data);
      setUser({ ...data?.result, token: data?.token });
      toast.success(data?.message || "Signed in successfully...");
      if (data?.result?.newPassword) {
        //Set value in session to show reset password modal
        sessionStorage.setItem("otpSent", JSON.stringify(true));
        handlePasswordModal();
      } else {
        navigate(`222`);
      }
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return (
    <div
      id="login"
      className="login flex flex-col justify-center w-full"
      style={{
        backgroundColor: "whitesmoke",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      {/*<AdminHeader />*/}
      <div className="w-full p-3 h-14 bg-inherit sticky top-0">
        <div className="h-full">
          {/* <h1 className="font-bold text-2xl text-orangeBg">VALOR</h1> */}
          <img src="/logo.png" className="w-10 h-10" />
        </div>
      </div>
      <div
        className=" border border-slate-300 flex flex-col justify-center md:flex-row md:justify-end items-center p-4 w-3/4 mx-auto shadow-lg rounded"
        style={{
          backgroundImage: `url(${bg1})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "90vh",
        }}
      >
        {/*Sign in form*/}
        <form
          className="w-full md:w-1/3 md:mr-10"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex md:items-center justify-start w-full  overflow-hidden">
            {/*<img className="object-contain h-full" src="/image.png" alt="logo" /> */}

            <h1 className="text-2xl md:w-3/4 object-contain font-medium text-darkBlue">
              Good {greetTime()},
            </h1>
          </div>
          <p className="my-4 text-lg text-slate-500">
            Please sign into your account
          </p>
          <div className="flex items-center justify-between gap-3">
            {/* <span className="border border-slate-300 bg-slate-50 block w-16 px-2 py-2 rounded-md mb-4 text-lg outline-none text-center">
              VAL
            </span> */}
            <input
              className="border border-slate-300 block flex-1 px-2 py-2 rounded-md mb-4 text-lg outline-none duration-200 focus:border-orangeBg focus:px-4 focus:ring-0"
              type="email"
              placeholder="Enter your e-mail..."
              required
              {...register("email", {
                required: true,
              })}
            />
          </div>
          <div className="w-full relative">
            <input
              className="border border-slate-300 block w-full px-2 py-2 rounded-md mb-4 text-lg outline-none duration-200 focus:border-orangeBg focus:px-4 focus:ring-0"
              text="text"
              required
              placeholder="Enter password..."
              type={showPassword ? "text" : "password"}
              {...register("password", {
                required: true,
              })}
            />
            {showPassword ? (
              <Visibility
                className="text-slate-400 text-lg absolute top-3 right-3 cursor-pointer"
                onClick={handlePassword}
              />
            ) : (
              <VisibilityOff
                className="text-slate-400 text-lg absolute top-3 right-3 cursor-pointer"
                onClick={handlePassword}
              />
            )}
          </div>
          <span className="text-slate-700 text-lg">
            <input type="checkbox" /> Keep me signed in
          </span>
          <br />
          <input
            id="submit"
            type="submit"
            value="Sign In"
            className="text-lg w-full mt-4 mb-4 mx-auto rounded-md cursor-pointer bg-darkBlue text-orangeBg py-2"
          />
          <div className="text-lg">
            Forgot Password?{" "}
            <span
              className="text-orangeBg cursor-pointer"
              onClick={handlePasswordModal}
            >
              Click to reset
            </span>
          </div>
        </form>
      </div>
      {/*Modal for password Reset */}
      <CenteredModal
        title={"RESET PASSWORD"}
        open={passwordModal}
        setOpen={handlePasswordModal}
        closeButtonLabel={<Close />}
      >
        <PasswordReset hideModal={handlePasswordModal} />
      </CenteredModal>
    </div>
  );
}

export default SigninPage;
