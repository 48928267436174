import React from "react";
import { Routes, Route } from "react-router-dom";
import CustomerSearch from "./CustomerSearch";
import CustomerDetails from "./CustomerDetails";

function CustomerRoutes() {
  return (
    <Routes>
      <Route path="/" element={<CustomerSearch />} />
      <Route path="/:customer_id" element={<CustomerDetails />} />
    </Routes>
  );
}

export default CustomerRoutes;
