import apiClient from "./api";

const client = apiClient();

export const caroptions = {
  getCarMake: ({ search, currentPage, perPage }) =>
    client
      .get(
        `/api/v1/getCarMake?limit=${perPage}&page=${currentPage}&search=${search}`
      )
      .then(({ data }) => data),

  deleteCarMake: (id) =>
    client.delete(`/api/v1/deleteCarMake/${id}`).then(({ data }) => data),

  addCarMake: (payload) =>
    client.post(`/api/v1/addCarMake`, payload).then(({ data }) => data),

  getCarModel: ({ search, currentPage, perPage }) =>
    client
      .get(
        `/api/v1/getCarModel?limit=${perPage}&page=${currentPage}&search=${search}`
      )
      .then(({ data }) => data),

  addCarModel: ({ id, payload }) =>
    client.post(`/api/v1/addCarModel/${id}`, payload).then(({ data }) => data),

  getCarMakeModels: (id) =>
    client.get(`/api/v1/getCarModel/${id}`).then(({ data }) => data),

  deleteCarModel: (id) =>
    client.delete(`/api/v1/deleteCarModel/${id}`).then(({ data }) => data),

  getCarType: ({ search, currentPage, perPage }) =>
    client
      .get(
        `/api/v1/getCarType?limit=${perPage}&page=${currentPage}&search=${search}`
      )
      .then(({ data }) => data),

  addCarType: (payload) =>
    client.post(`/api/v1/createCarType`, payload).then(({ data }) => data),

  deleteCarType: (id) =>
    client.delete(`/api/v1/deleteCarType/${id}`).then(({ data }) => data),

  getColor: ({ search, currentPage, perPage }) =>
    client
      .get(
        `/api/v1/getColor?limit=${perPage}&page=${currentPage}&search=${search}`
      )
      .then(({ data }) => data),

  addCarColor: (payload) =>
    client.post(`/api/v1/createCarColor`, payload).then(({ data }) => data),

  deleteCarColor: (id) =>
    client.delete(`/api/v1/deleteCarColor/${id}`).then(({ data }) => data),
};
