import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { customers } from "../../api/customers";
import Loader from "../../components/Loader/Loader";
import { Flex, Box, Text, Switch } from "@theme-ui/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import { GavelOutlined, Close } from "@material-ui/icons";
import { useParams, useNavigate } from "react-router-dom";
import CenteredModal from "../../components/Modal/CenteredModal";
import IssueStrike from "./components/IssueStrike";
import ActivateDeactivate from "./components/ActivateDeactivate";
import Transactions from "./components/Transactions";
import History from "./components/History";
import Summary from "./components/Summary";

const CustomerDetails = () => {
  const navigate = useNavigate();
  const { customer_id } = useParams();

  const [modal, setModal] = useState(false);
  const [strikeModal, setStrikeModal] = useState(false);

  const currentScreenIndex = JSON.parse(
    sessionStorage.getItem("customerScreenIndex")
  );

  // console.log(currentScreenIndex);

  const subScreens = [
    { title: "Transactions", page: <Transactions /> },
    { title: "Trip History", page: <History /> },
    { title: "Account Summary", page: <Summary /> },
  ];

  const [activeScreenIndex, setActiveScreenIndex] = useState(0);

  // console.log(activeScreenIndex);

  const handleScreenIndex = (index) => {
    return () => {
      setActiveScreenIndex(index);
      sessionStorage.setItem("customerScreenIndex", index);
    };
  };

  useEffect(() => {
    if (currentScreenIndex && currentScreenIndex > subScreens.length) {
      setActiveScreenIndex(currentScreenIndex);
    }
  }, []);

  const {
    data: selectedCustomer,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["selectedCustomer", customer_id],
    () => customers.getCustomer(customer_id),
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data?.data,
    }
  );

  const { data: amount } = useQuery(
    ["Customer Amount Holding", customer_id],
    () => customers.getHoldingAmount(customer_id),
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data?.data,
    }
  );

  //   console.log(amount);

  //   console.log(selectedCustomer);

  const ReturnBtn = () => {
    return (
      <Button
        style={{ marginTop: "10px" }}
        onClick={() => {
          sessionStorage.removeItem("currentScreenIndex");
          navigate(-1);
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
    );
  };

  if (isLoading) {
    return (
      <React.Fragment>
        <ReturnBtn />
        <Loader />
      </React.Fragment>
    );
  }

  if (isError) {
    return (
      <React.Fragment>
        <ReturnBtn />
        <div className="w-full flex items-center gap-2 justify-center h-[200px]">
          <FontAwesomeIcon icon={faExclamationCircle} /> Error, kindly reload
          the page...
        </div>
      </React.Fragment>
    );
  }

  const SubInfo = ({ title, content }) => {
    return (
      <Box mb={3}>
        <Text mr={1} className="font-medium uppercase">
          {title}
        </Text>
        <Text className="capitalize">{content || "-"}</Text>
      </Box>
    );
  };

  //   console.log(customer_id);

  return (
    <React.Fragment>
      <CenteredModal
        title={""}
        open={modal}
        setOpen={() => setModal(!modal)}
        closeButtonLabel={<Close />}
      >
        <ActivateDeactivate
          accountStatus={selectedCustomer?.accountStatus}
          id={customer_id}
          handleModal={() => setModal(!modal)}
        />
      </CenteredModal>
      <CenteredModal
        title={""}
        open={strikeModal}
        setOpen={() => setStrikeModal(!strikeModal)}
        closeButtonLabel={<Close />}
      >
        <IssueStrike
          id={customer_id}
          handleModal={() => setStrikeModal(!strikeModal)}
        />
      </CenteredModal>
      <Box>
        <ReturnBtn />
      </Box>

      {/*CUSTOMER DETAILS*/}

      <Flex
        className="border border-slate-300 w-[99.7%] pt-7 pb-2 px-7"
        sx={{
          marginTop: "20px",
          marginBottom: "50px",
          gap: "20px",
          flexDirection: ["column", "row"],
          boxShadow: "3px 3px 1px 0 #023047",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <SubInfo
            title={"Name:"}
            content={
              selectedCustomer?.fName
                ? `${selectedCustomer?.fName} ${selectedCustomer?.lName}`
                : "-"
            }
          />
          <SubInfo title={"E-mail:"} content={selectedCustomer?.email} />
          <SubInfo
            title={"Phone Number:"}
            content={selectedCustomer?.phoneNumber}
          />
          <SubInfo
            title={"Strikes:"}
            content={selectedCustomer?.numberOfStrikes}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <SubInfo
            title={"Amount in holding:"}
            content={amount ? `NGN ${amount?.toLocaleString()}` : "-"}
          />
          <SubInfo
            title={"Number of Bookings:"}
            content={selectedCustomer?.numberOfBookings}
          />
          <SubInfo
            title={"Number of Cancellations:"}
            content={selectedCustomer?.numberOfCancellations}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Switch
            label={
              selectedCustomer?.accountStatus === "yes"
                ? "Deactivate Account?"
                : "Activate Account?"
            }
            checked={selectedCustomer?.accountStatus === "yes" ? true : false}
            onClick={() => setModal(true)}
            sx={{
              bakgroundColor: "gray",
              "input:checked ~ &": {
                backgroundColor: "#fb8500",
              },
            }}
          />
          <Box
            mt={3}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <a title="Issue Strike">
              <button
                onClick={() => setStrikeModal(!strikeModal)}
                className="border border-slate-300 bg-orange-100 font-bold text-4xl p-2 w-12 flex items-center justify-center cursor-pointer text-darkBlue rounded-lg shadow hover:bg-darkBlue hover:text-white duration-200 relative"
              >
                <GavelOutlined />
              </button>
            </a>
            <Text>Issue Strike?</Text>
          </Box>
        </Box>
      </Flex>

      <Flex sx={{ marginTop: "20px", flexDirection: "column" }}>
        <Box
          sx={{
            borderBottom: "1px solid #023047",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            width: "100%",
          }}
        >
          {subScreens.map((item, index) => (
            <button
              key={index}
              onClick={handleScreenIndex(index)}
              className={`${
                activeScreenIndex === index && "bg-darkBlue text-white"
              } uppercase text-sm py-3 px-5`}
            >
              {item.title}
            </button>
          ))}
        </Box>

        {subScreens[activeScreenIndex].page}
      </Flex>
    </React.Fragment>
  );
};

export default CustomerDetails;
