import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { codes } from "../../../helpers/countryCode";
import { ManageStaffContext } from "../ManageStaffContext";
import { inputClass, divClass } from "../Style";
import { api } from "../../../api";
import { staff } from "../../../api/staff";
import { useMutation } from "react-query";

const AddStaff = ({ refetch, hideModal, prePermissions }) => {
  const { mutateAsync } = useMutation(staff.addStaff);
  const [role, setRole] = useState("l1");
  const [permissions, setPermissions] = useState([
    "createUsers",
    "editUsers",
    "deleteUsers",
    "activateUsers",
    "createTeams",
    "assignTeams",
    "hostStrike",
    "addReview",
    "deleteReview",
    "approval",
  ]);

  const { register, handleSubmit } = useForm();

  const { data } = useContext(ManageStaffContext);

  const inviteStaff = async (values) => {
    toast.loading("Processing...");
    // const foundCountry = codes.find(
    //   (country) => country.name === values?.country
    // );

    const payload = {
      ...values,
      // role: role,
      // countryCode: foundCountry?.code,
      phone: "+234" + parseInt(values?.phone),
      permissions: permissions,
    };

    try {
      await mutateAsync(payload);
      // console.log(data);
      toast.remove();
      toast.success("Successfully added staff...");
      refetch();
      setTimeout(() => {
        hideModal();
      }, 500);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  const handlePermissions = (e) => {
    const permission = e.target.value;

    if (permissions.includes(permission)) {
      // If the permission already exists, remove it from the array
      setPermissions(
        permissions.filter(
          (currentPermission) => currentPermission !== permission
        )
      );
    } else {
      // If the permission doesn't exist, add it to the array
      setPermissions([...permissions, permission]);
    }
  };

  return (
    <React.Fragment>
      <form
        className="p-3 grid grid-cols-1 md:grid-cols-2 sm:grid-cols-1 gap-4 border border-slate-300"
        onSubmit={handleSubmit(inviteStaff)}
      >
        <div className={divClass}>
          <p>First Name</p>
          <input
            className={inputClass}
            required
            type="text"
            {...register("fName", {
              required: true,
            })}
          />
        </div>
        <div className={divClass}>
          <p>Last Name</p>
          <input
            className={inputClass}
            type="text"
            required
            {...register("lName", {
              required: true,
            })}
          />
        </div>
        <div className={divClass}>
          <p>Email</p>
          <input
            className={inputClass}
            type="email"
            required
            {...register("email", {
              required: true,
            })}
          />
        </div>
        <div className={divClass}>
          <p>Phone Number</p>
          <input
            className={inputClass}
            type="number"
            required
            {...register("phone", {
              required: true,
            })}
          />
        </div>
        {/* <div className={divClass}>
          <p>Select Role</p>
          <select className={inputClass} value={role} onChange={handleRole}>
            <option value={"admin"}>Admin</option>
            {roles?.map((role) => (
              <option key={role.id} value={role.slug}>
                {role?.title}
              </option>
            ))}
          </select>
        </div>
        <div className={divClass}>
          <p>Select Country</p>
          <select
            className={inputClass}
            defaultValue={"Nigeria"}
            {...register("country", {
              required: true,
            })}
          >
            {codes?.map((country, index) => (
              <option key={index} value={country?.name}>
                {country?.name}
              </option>
            ))}
          </select>
        </div>
        <div className={divClass}>
          <p>Select Team</p>
          <select
            className={inputClass}
            {...register("teamId", {
              required: true,
            })}
          >
            {teams?.data?.map((team) => (
              <option key={team?._id} value={team?._id}>
                {team?.name}
              </option>
            ))}
          </select>
        </div>
        <div className={divClass}>
          <p>Staff Id</p>
          <input
            className={inputClass}
            type="text"
            defaultValue={"VAL-"}
            required
            {...register("staffId", {
              required: true,
            })}
          />
        </div> */}
        <div className={divClass}>
          <p>Permissions</p>
          <div className="w-full p-3 grid rounded-md grid-cols-1 md:grid-cols-2 sm:grid-cols-1 gap-4 border border-slate-300">
            {prePermissions?.map((staffPermission, index) => (
              <span
                key={index}
                className="flex items-center justify-start gap-2"
              >
                <input
                  type="checkbox"
                  className="focus:ring-0 cursor-pointer"
                  value={staffPermission?.permission}
                  onChange={handlePermissions}
                  checked={
                    permissions.find(
                      (permission) => permission === staffPermission?.permission
                    )
                      ? true
                      : false
                  }
                />
                <label>{staffPermission?.permission}</label>
              </span>
            ))}
          </div>
        </div>
        <div className="flex justify-end items-end">
          <button
            type="submit"
            className="mt-5 px-5 py-2 border border-orangeBg bg-orangeBg text-white rounded duration-100 hover:text-darkBlue hover:bg-orange-200 hover:border-orange-200"
          >
            PROCEED
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default AddStaff;
