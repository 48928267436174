import classes from "./AdminSidebar.module.css";
import SidebarItem from "../SidebarItem/SidebarItem";
import useWindowDimensions from "../../hooks/useWindowSize";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const AdminSidebar = ({
  pages,
  isOpen,
  setIsOpen,
  windowWidth,
  refreshTime,
}) => {
  const handleItemSelect = (id) => {
    if (windowWidth < 768) {
      setIsOpen(false);
    }
  };

  const location = useLocation();

  return (
    <div
      className={`${classes.Sidebar} ${
        isOpen ? classes.OpenSidebar : classes.CloseSidebar
      } absolute md:sticky flex-1  bg-darkBlue z-10`}
    >
      <div>
        <div className="mb-3 p-5">
          {/* <h3>Dashboard</h3> */}
          <ul>
            {pages.map((page) => (
              <SidebarItem
                key={page.id}
                label={page.title}
                icon={page.icon}
                path={page.path}
                active={location.pathname.includes(page.path) ? true : false}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
