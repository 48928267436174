import React from "react";
import { GavelOutlined, ErrorOutlineOutlined } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshakeAlt } from "@fortawesome/free-solid-svg-icons";
import styles from "./Complaints.module.css";
import { useState } from "react";
import Dispute from "./components/Dispute";
import ArrivalForm from "./components/ArrivalForm";
import Chat from "./components/Chat";

const DisputeCall = () => {
  const [currentTab, setCurrentTab] = useState("dispute");

  return (
    <>
      <div className="flex p-2 justify-end gap-2">
        <a title="Settle Dispute">
          <div className="border border-slate-300 bg-orange-100 font-bold text-2xl p-2 w-12 flex items-center justify-center cursor-pointer text-darkBlue rounded-lg shadow hover:bg-darkBlue hover:text-orange-100 hover:shadow-none duration-200">
            <FontAwesomeIcon icon={faHandshakeAlt} />
          </div>
        </a>
        <a title="Pass Judgement">
          <div className="border border-slate-300 bg-orange-100 font-bold text-4xl p-2 w-12 flex items-center justify-center cursor-pointer text-darkBlue rounded-lg shadow hover:bg-darkBlue hover:text-orange-100 hover:shadow-none duration-200 relative">
            <GavelOutlined />
          </div>
        </a>
        <a title="Customer Information">
          <div className="border border-slate-300 bg-orange-100 font-bold text-4xl p-2 w-12 flex items-center justify-center cursor-pointer text-darkBlue rounded-lg shadow hover:bg-darkBlue hover:text-orange-100 hover:shadow-none duration-200">
            <ErrorOutlineOutlined />
          </div>
        </a>
      </div>
      <div className={`${styles.DisputeCall}`}>
        <ul>
          <li
            onClick={() => {
              setCurrentTab("dispute");
            }}
            className={
              currentTab === "dispute"
                ? "border border-darkBlue bg-darkBlue text-white"
                : null
            }
          >
            Dispute
          </li>
          <li
            onClick={() => {
              setCurrentTab("arrival");
            }}
            className={
              currentTab === "arrival"
                ? "border border-darkBlue bg-darkBlue text-white"
                : null
            }
          >
            Arrival Form
          </li>
          <li
            onClick={() => {
              setCurrentTab("chat");
            }}
            className={
              currentTab === "chat"
                ? "border border-darkBlue bg-darkBlue text-white"
                : null
            }
          >
            Chat
          </li>
        </ul>
        {currentTab === "dispute" && <Dispute />}
        {currentTab === "arrival" && <ArrivalForm />}
        {currentTab === "chat" && <Chat />}
      </div>
    </>
  );
};

export default DisputeCall;
