import React from "react";
import { useState, useEffect } from "react";
import styles from "../Complaints.module.css";
import { BeatLoader } from "react-spinners";
import { Send } from "@material-ui/icons";

const Dispute = () => {
  const [loading, setLoading] = useState(true);
  const [number, setNumber] = useState(1);

  return (
    <>
      {/* {setInterval(() => {
        setNumber(number + 1);
        console.log(number);
        return <h1 className="text-red-500">{number}</h1>;
      }, 5000)} */}
      <div className={`${styles.DisputeBox}`}>
        <div className="flex my-2 py-4 px-2">
          <span
            className="border border-darkBlue bg-darkBlue text-white p-2 relative"
            style={{ borderRadius: "0 10px 10px 10px" }}
          >
            <p>Can we negotiate the price a bit? It is a bit high.</p>
          </span>
        </div>
        <div className="flex my-2 py-4 px-2 justify-end">
          <span
            className="border border-slate-300 bg-slate-300 text-darkBlue p-2 relative"
            style={{ borderRadius: "10px 0 10px 10px" }}
          >
            <p>Can we negotiate the price a bit? It is a bit high.</p>
          </span>
        </div>
        <div className="flex my-2 py-4 px-2">
          <span
            className="border border-darkBlue bg-darkBlue text-white p-2 relative"
            style={{ borderRadius: "0 10px 10px 10px" }}
          >
            <p>Can we negotiate the price a bit? It is a bit high.</p>
          </span>
        </div>
        <div className="flex my-2 py-4 px-2 justify-end">
          <span
            className="border border-slate-300 bg-slate-300 text-darkBlue p-2 relative"
            style={{ borderRadius: "10px 0 10px 10px" }}
          >
            <p>Can we negotiate the price a bit? It is a bit high.</p>
          </span>
        </div>
        <div className="flex my-2 py-4 px-2">
          <span
            className="border border-darkBlue bg-darkBlue text-white p-2 relative"
            style={{ borderRadius: "0 10px 10px 10px" }}
          >
            <p>Can we negotiate the price a bit? It is a bit high.</p>
          </span>
        </div>
        <div className="flex my-2 py-4 px-2 justify-end">
          <span
            className="flex items-center justify-center border border-slate-300 bg-slate-300 text-white p-2 relative"
            style={{ borderRadius: "10px 0 10px 10px" }}
          >
            <BeatLoader
              size={10}
              color={"#023047"}
              loading={loading}
              className="loaderr"
            />
          </span>
        </div>
      </div>
      <div className="flex gap-3 my-2 py-4 px-2">
        {/* <input
          type="text"
          className="flex-1 outline-none border border-slate-300 rounded focus:outline-none text-darkBlue"
          placeholder="Enter message..."
        /> */}
        <input
          className="flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none duration-200 focus:px-5 focus:border-orangeBg"
          text="text"
          placeholder="Enter message..."
        />
        <button className="px-5 border border-orangeBg bg-orangeBg font-bold text-white rounded duration-100 hover:text-darkBlue hover:bg-orange-200 hover:border-orange-200">
          <Send />
        </button>
      </div>
    </>
  );
};

export default Dispute;
