import {
  AccountBoxRounded,
  AccountBalanceWalletRounded,
  NoteOutlined,
  SearchOutlined,
  CheckCircleOutlineOutlined,
  PeopleAltOutlined,
  DirectionsCarRounded,
} from "@material-ui/icons";

export const ROUTES = Object.freeze({
  admin: [
    {
      id: 0,
      title: "COMPLAINTS",
      icon: <NoteOutlined />,
      path: "complaints",
    },
    {
      id: 1,
      title: "ASSIGNED TRANSACTIONS",
      icon: <AccountBalanceWalletRounded />,
      path: "assigned-transactions",
    },
    {
      id: 2,
      title: "MANAGE STAFF",
      icon: <AccountBoxRounded />,
      path: "manage-staff",
    },
    {
      id: 3,
      title: "CAR OPTIONS",
      icon: <DirectionsCarRounded />,
      path: "car-options",
    },
    {
      id: 4,
      title: "VENDOR SEARCH",
      icon: <SearchOutlined />,
      path: "vendor-search",
    },
    {
      id: 5,
      title: "VENDOR APPROVAL",
      icon: <CheckCircleOutlineOutlined />,
      path: "vendor-approval",
    },
    {
      id: 6,
      title: "CUSTOMER SEARCH",
      icon: <PeopleAltOutlined />,
      path: "customer-search",
    },
    {
      id: 7,
      title: "LONG-TERM LEASING",
      icon: <DirectionsCarRounded />,
      path: "longterm-leasing",
    },
  ],
  l1: [
    {
      id: 0,
      title: "Complaints",
      icon: <NoteOutlined />,
      path: "complaints",
    },
    {
      id: 1,
      title: "Assigned transactions",
      icon: <AccountBalanceWalletRounded />,
      path: "assigned-transactions",
    },
    {
      id: 2,
      title: "Vendor Search",
      icon: <SearchOutlined />,
      path: "vendor-search",
    },
  ],
  l2: [
    {
      id: 0,
      title: "Complaints",
      icon: <NoteOutlined />,
      path: "complaints",
    },

    {
      id: 1,
      title: "Vendor Search",
      icon: <SearchOutlined />,
      path: "vendor-search",
    },
    {
      id: 2,
      title: "Vendor Approval",
      icon: <CheckCircleOutlineOutlined />,
      path: "vendor-approval",
    },
  ],
});
