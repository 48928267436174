import React from "react";
import { ErrorOutlineOutlined } from "@material-ui/icons";

const NotPermitted = () => {
  return (
    <div className="w-full h-[90%] py-5 flex items-center justify-center gap-2">
      <ErrorOutlineOutlined className="text-orangeBg" />
      <p>You are not permitted to view this page...</p>
    </div>
  );
};
export const JointProtectedRoute = ({ requiredPermissions, children }) => {
  const permissions = JSON.parse(sessionStorage.getItem("user"))?.permissions;

  //   console.log(permissions);
  // Check if all required permissions are present
  const hasPermission = requiredPermissions.every((permission) =>
    permissions.some((p) => p === permission)
  );
  //   console.log(hasPermission);

  // Render children if permission is granted, otherwise render null
  return hasPermission ? children : <NotPermitted />;
};

const ProtectedRoute = ({ requiredPermissions, children }) => {
  const permissions = JSON.parse(sessionStorage.getItem("user"))?.permissions;

  //   console.log(permissions);
  // Check if all required permissions are present
  const hasPermission = requiredPermissions.some((permission) =>
    permissions.some((p) => p === permission)
  );
  //   console.log(hasPermission);

  // Render children if permission is granted, otherwise render null
  return hasPermission ? children : <NotPermitted />;
};

export default ProtectedRoute;
