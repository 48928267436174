/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Signin from "./pages/Signin";
import toast, { Toaster } from "react-hot-toast";
import { UserContext } from "./context/UserContext";
import AdminDashboard from "./pages/AdminDashboard";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider } from "@theme-ui/core";

const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();
  const [user, setUser] = useState("");
  const location = useLocation();
  const currentRoute = location.pathname;

  useEffect(() => {
    const userData = sessionStorage.getItem("user");
    const user = JSON.parse(userData);
    if (userData) {
      setUser(user);
    } else if (!userData && currentRoute.includes(`/${user?._id}`)) {
      navigate("/");
      toast.remove();
      toast("We have signed you out!");
    }
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <UserContext.Provider value={{ user, setUser }}>
          <ThemeProvider>
            <Routes>
              <Route path="/" exact element={<Signin />} />
              <Route path="/:id/*" exact element={<AdminDashboard />} />
            </Routes>
          </ThemeProvider>
        </UserContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      </QueryClientProvider>
      <Toaster
        toastOptions={{
          style: {
            color: "#023047",
          },
          success: {
            iconTheme: {
              primary: "#fb8500",
            },
            style: {
              color: "#023047",
            },
          },
        }}
      />
    </>
  );
}

export default App;
