import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../context/UserContext";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { ManageStaffContext } from "../ManageStaffContext";
import { api } from "../../../api";
import { useMutation } from "react-query";

const AddTeam = ({ refetch }) => {
  const { register, handleSubmit } = useForm();
  const { mutateAsync } = useMutation(api.createTeam);

  const { data } = useContext(ManageStaffContext);

  const { staffs } = data;

  // console.log({ staffs });

  const createTeam = async (values) => {
    toast.loading("Processing...");

    try {
      await mutateAsync(values);
      toast.remove();
      toast.success("Team created successfully...");
      refetch();
    } catch ({ response }) {
      const { data } = response;
      toast.remove();
      toast.error(data?.message);
    }
  };

  return (
    <>
      <form
        className="p-3 grid grid-cols-1 md:grid-cols-2 sm:grid-cols-1 gap-4 border border-slate-300"
        onSubmit={handleSubmit(createTeam)}
      >
        <div className="flex items-start flex-col justify-center gap-1">
          <p>Team Name</p>
          <input
            className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:px-5 focus:border-orangeBg focus:bg-white"
            text="text"
            placeholder="Team A"
            {...register("name", {
              required: true,
            })}
          />
        </div>
        <div className="flex items-start flex-col justify-center gap-1">
          <p>Team Leader</p>
          <select
            className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:px-5 focus:border-orangeBg focus:bg-white focus:ring-0"
            {...register("staffId", {
              required: true,
            })}
          >
            {staffs?.data?.map((currentstaff) => (
              <option
                key={currentstaff?._id}
                value={currentstaff?.staff?.staffId}
              >
                {currentstaff?.staff?.firstName} {currentstaff?.staff?.lastName}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-start items-center">
          <button
            type="submit"
            className="mt-5 px-5 py-2 border border-orangeBg bg-orangeBg text-white rounded duration-100 hover:text-darkBlue hover:bg-orange-200 hover:border-orange-200"
          >
            PROCEED
          </button>
        </div>
      </form>
    </>
  );
};

export default AddTeam;
