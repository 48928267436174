export const inputClass =
  "w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:px-5 focus:border-orangeBg focus:bg-white focus:ring-0";

export const divClass = "flex items-start flex-col justify-center gap-1";

export const backBtnClass =
  "text-sm flex items-center justify-center h-10 w-10 p-2 hover:bg-gray-100 bg-transparent text-darkBlue duration-300 my-2";

export const vertButtonClass =
  "block w-full font-normal border border-slate-100 px-4 py-2 text-left bg-white duration-200 text-baseFont text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer";
