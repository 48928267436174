import React from "react";
import ViewOpenLeasing from "./components/ViewOpenLeasing";
import AcceptedLeasingList from "./components/AcceptedLeasingList";
import useWindowDimensions from "../../hooks/useWindowSize";

const LongTermLeasing = () => {
  const { width } = useWindowDimensions();

  return (
    <div
      div
      className={`p-2 flex items-start justify-center gap-2 ${
        width > 600 ? "flex-row" : "flex-col"
      }`}
    >
      <ViewOpenLeasing width={width} />
      <AcceptedLeasingList width={width} />
    </div>
  );
};

export default LongTermLeasing;
