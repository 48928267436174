export function convertDate(isoDate) {
  if (!isoDate) return "";

  let timestamp = Date.parse(isoDate);
  let jsDate = new Date(timestamp);
  let fDate = new Intl.DateTimeFormat("en-uk", {
    dateStyle: "short",
    timeStyle: "short",
  });
  return fDate.format(jsDate);
}
