import React from "react";
import styles from "./Complaints.module.css";
import { useState, useContext } from "react";
import { useQuery } from "react-query";
import useWindowDimensions from "../../hooks/useWindowSize";
import ApprovalType from "./ApprovalType";
import { convertDate } from "../../helpers/convertDate";
import { UserContext } from "../../context/UserContext";
import { approvals as getData } from "../../api/approval";

const VendorApproval = () => {
  const user = useContext(UserContext);
  const [activeTab, setActiveTab] = useState("car");
  const [activeID, setActiveID] = useState(0);
  const { width } = useWindowDimensions();

  const [sort, setSort] = useState("");

  const { data: approvals, isLoading } = useQuery(
    ["Approvals", activeTab, sort],
    async () => {
      const data = await getData.getApprovals({
        type: activeTab === "car" ? 1 : 2,
        sort,
      });
      return data;
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnWindowFocus: true,
      select: (data) => data?.message,
    }
  );

  // console.log(approvals);

  return (
    // <>
    //   <p>Vendor Approval</p>
    // </>
    <>
      <div className="flex items-center w-full justify-between gap-[1px] border-b border-darkBlue">
        <ul className="flex items-end gap-[1px] justify-start mt-2">
          <li
            className={
              activeTab === "car"
                ? "px-5 py-2 border border-darkBlue bg-darkBlue text-white font-bold cursor-pointer"
                : "px-5 py-2 text-darkBlue cursor-pointer"
            }
            onClick={() => {
              setActiveTab("car");
              setActiveID(0);
            }}
          >
            CAR
          </li>
          <li
            className={
              activeTab === "driver"
                ? "px-5 py-2 border border-darkBlue bg-darkBlue text-white font-bold cursor-pointer"
                : "px-5 py-2 text-darkBlue cursor-pointer"
            }
            onClick={() => {
              setActiveTab("driver");
              setActiveID(0);
            }}
          >
            DRIVER
          </li>
        </ul>
        <select onChange={(e) => setSort(e.target.value)}>
          <option disabled selected>
            Sort by
          </option>
          <option value={""}>Default</option>
          <option value={"approvalEligibleDate"}>Approval Eligible Date</option>
        </select>
      </div>
      <div
        className={`p-2 flex items-start justify-center gap-2 ${
          width > 600 ? "flex-row" : "flex-col"
        }`}
      >
        <div
          className={`border-x-1 border-slate-300 ${
            width > 600 ? "w-1/4" : "w-full"
          }`}
        >
          <div className={`${styles.DisputeList}`}>
            {activeTab === "driver" && (
              <ul>
                {approvals?.map((item, index) => (
                  <li
                    className={
                      activeID === index
                        ? "flex p-3 bg-darkBlue cursor-pointer text-white"
                        : "flex p-3 bg-white cursor-pointer border border-slate-300 text-darkBlue"
                    }
                    key={index}
                    onClick={() => {
                      setActiveID(index);
                    }}
                  >
                    <div className="flex gap-3 flex-1 flex-col">
                      <h1 className="font-bold">
                        {item?.lName} {item?.fName}
                      </h1>
                      <h3>{item?.driversLicenseNumber}</h3>
                      <span>{convertDate(item?.approvalEligibleDate)}</span>
                      <h3>Strikes: {item?.numberOfStrikes}</h3>
                    </div>
                    <div className="px-3 flex items-end justify-end">
                      <span
                        className={
                          activeID === index
                            ? "border border-siderbarBg bg-siderbarBg rounded px-4 py-2 text-orangeBg"
                            : "border border-slate-300 bg-slate-300 rounded px-4 py-2 text-darkBlue"
                        }
                      >
                        {item?.address}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {activeTab === "car" && (
              <ul>
                {approvals?.map((item, index) => (
                  <li
                    className={
                      activeID === index
                        ? "flex p-3 bg-darkBlue cursor-pointer text-white"
                        : "flex p-3 bg-white cursor-pointer border border-slate-300 text-darkBlue"
                    }
                    key={index}
                    onClick={() => {
                      setActiveID(index);
                    }}
                  >
                    <div className="flex gap-3 flex-1 flex-col">
                      <h1 className="font-bold">
                        {item?.carDetails?.carMake} {item?.carDetails?.carModel}
                      </h1>
                      <span>{convertDate(item?.approvalEligibleDate)}</span>
                      <h3>{item?.plateNumber}</h3>
                    </div>
                    <div className="px-3 flex items-end justify-end">
                      {item?.adminApproval?.status && (
                        <span
                          className={
                            activeID === index
                              ? "border border-siderbarBg bg-siderbarBg rounded px-4 py-2 text-orangeBg capitalize"
                              : "border border-slate-300 bg-slate-300 rounded px-4 py-2 text-darkBlue capitalize"
                          }
                        >
                          {item?.adminApproval?.status}
                        </span>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div
          className={`border border-slate-300 ${
            width > 600 ? "w-3/4" : "w-full"
          } rounded p-2`}
        >
          <ApprovalType
            type={activeTab}
            data={approvals && approvals[activeID]}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default VendorApproval;
