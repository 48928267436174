import axios from "axios";
import baseUrl from "../url";
import toast from "react-hot-toast";

const apiClient = () => {
  const client = axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
    },
  });

  client.interceptors.request.use(
    async (config) => {
      const userToken = JSON.parse(sessionStorage.getItem("user"))?.token;
      if (userToken) {
        config.headers.Authorization = `Bearer ${userToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      // if (error?.response?.status === 401 && error?.config?.url !== "/login") {

      // }
      return Promise.reject(error);
    }
  );
  return client;
};

export default apiClient;
