import React, { useState } from "react";
import { Flex, Box } from "@theme-ui/components";
import Make from "./components/Make";
import Model from "./components/Model";
import Type from "./components/Type";
import Color from "./components/Color";

const CarOptions = () => {
  const subScreens = [
    { title: "Make", page: <Make /> },
    { title: "Model", page: <Model /> },
    { title: "Type", page: <Type /> },
    { title: "Color", page: <Color /> },
  ];

  const currentScreenIndex = JSON.parse(
    sessionStorage.getItem("optionScreenIndex")
  );

  const [activeScreenIndex, setActiveScreenIndex] = useState(
    currentScreenIndex || 0
  );

  const handleScreenIndex = (index) => {
    return () => {
      setActiveScreenIndex(index);
      sessionStorage.setItem("optionScreenIndex", index);
    };
  };

  return (
    <Flex sx={{ marginTop: "20px", flexDirection: "column" }}>
      <Box
        sx={{
          borderBottom: "1px solid #023047",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        {subScreens.map((item, index) => (
          <button
            key={index}
            onClick={handleScreenIndex(index)}
            className={`${
              activeScreenIndex === index && "bg-darkBlue text-white"
            } uppercase text-sm py-3 px-5`}
          >
            {item.title}
          </button>
        ))}
      </Box>

      {subScreens[activeScreenIndex].page}
    </Flex>
  );
};

export default CarOptions;
