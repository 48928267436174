import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { longtermleasing as getListing } from "../../api/longtermleasing";
import Loader from "../../components/Loader/Loader";
import { Flex, Box, Text } from "@theme-ui/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const ViewLeasingItem = () => {
  const navigate = useNavigate();
  const { leasing_id } = useParams();

  const { mutateAsync: closeItem } = useMutation(
    getListing.closeLongTermListing
  );

  const { data, isLoading, isError } = useQuery(
    ["Current Leasing Item", leasing_id],
    () => getListing.getLongTermListing(leasing_id),
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data?.data,
    }
  );

  const handleCloseItem = () => {
    const handleItem = async (status) => {
      try {
        toast.loading("Processing...");
        const data = await closeItem({
          id: leasing_id,
          payload: { businessSecured: status },
        });
        // console.log(data);
        toast.remove();
        toast.success(data?.message);
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      } catch ({ response }) {
        toast.remove();
        toast.error(response?.data?.message);
      }
    };

    if (confirm("Kindly confirm if this business was secured or not?")) {
      handleItem(true);
    } else {
      handleItem(false);
    }
  };

  const ReturnBtn = () => {
    return (
      <Button
        style={{ marginTop: "10px" }}
        onClick={() => {
          navigate(-1);
        }}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
    );
  };

  if (isLoading) {
    return (
      <React.Fragment>
        <ReturnBtn />
        <Loader />
      </React.Fragment>
    );
  }

  if (isError) {
    return (
      <React.Fragment>
        <ReturnBtn />
        <div className="w-full flex items-center gap-2 justify-center h-[200px]">
          <FontAwesomeIcon icon={faExclamationCircle} /> Error, kindly reload
          the page...
        </div>
      </React.Fragment>
    );
  }

  // console.log(data);

  const SubInfo = ({ title, content }) => {
    return (
      <Box mb={3}>
        <Text mr={1} className="font-medium uppercase">
          {title}
        </Text>
        <Text className="capitalize">{content || "-"}</Text>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Box>
        <ReturnBtn />
      </Box>
      <Flex
        className="border border-slate-300 w-[99.7%] pt-7 pb-2 px-7"
        sx={{
          marginTop: "20px",
          marginBottom: "50px",
          gap: "20px",
          flexDirection: ["column", "row"],
          boxShadow: "3px 3px 1px 0 #023047",
        }}
      >
        <Box sx={{ flex: 1, gap: "20px" }}>
          <SubInfo
            title={"Name:"}
            content={data?.fName ? `${data?.fName} ${data?.lName}` : "-"}
          />
          <SubInfo title={"E-mail:"} content={data?.email} />
          <SubInfo title={"Phone Number:"} content={data?.phoneNumber} />
          <SubInfo title={"State:"} content={data?.state} />
          <SubInfo title={"Start Date:"} content={data?.startDate} />
        </Box>
        <Box sx={{ flex: 1, gap: "20px" }}>
          <SubInfo
            title={"Car Type:"}
            content={
              data?.carType
                ? data?.carType
                    ?.map((car) => `${car?.carType} (${car?.quantity})`)
                    .join(", ")
                : "-"
            }
          />
          <SubInfo title={"Platform:"} content={data?.platform} />
          <SubInfo title={"Duration:"} content={data?.duration} />
          <SubInfo title={"Note:"} content={data?.note} />
          <SubInfo title={"Itinerary:"} content={data?.itinerary} />
        </Box>
        <Box className="flex-[0.5] relative">
          <button
            onClick={handleCloseItem}
            className="border absolute bottom-0 right-0 border-darkBlue bg-darkBlue py-3 px-10 text-sm text-white"
          >
            CLOSE THIS ITEM
          </button>
        </Box>
      </Flex>
    </React.Fragment>
  );
};

export default ViewLeasingItem;
