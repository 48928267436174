import React from "react";
import { SearchOutlined } from "@material-ui/icons";
import styles from "./Complaints.module.css";
import { useState } from "react";
import { activeGridFilterItemsSelector } from "@material-ui/data-grid";
import DisputeCall from "./DisputeCall";
import useWindowDimensions from "../../hooks/useWindowSize";

const complaints = [
  { name: "Fleet Rentals", car: "Toyota Corolla 2020", time: "10:23pm" },
  { name: "Fleet Rentals", car: "Toyota Venza", time: "12:23pm" },
  { name: "Fleet Rentals", car: "Benz C-Class", time: "10:23am" },
  { name: "Fleet Rentals", car: "Toyota Corolla 2020", time: "10:23pm" },
  { name: "Fleet Rentals", car: "Toyota Corolla 2020", time: "10:23pm" },
  { name: "Fleet Rentals", car: "Toyota Corolla 2020", time: "10:23pm" },
  { name: "Fleet Rentals", car: "Toyota Corolla 2020", time: "10:23pm" },
  { name: "Fleet Rentals", car: "Toyota Corolla 2020", time: "10:23pm" },
  { name: "Fleet Rentals", car: "Toyota Corolla 2020", time: "10:23pm" },
  { name: "Fleet Rentals", car: "Toyota Corolla 2020", time: "10:23pm" },
  { name: "Fleet Rentals", car: "Toyota Corolla 2020", time: "10:23pm" },
  { name: "Fleet Rentals", car: "Toyota Corolla 2020", time: "10:23pm" },
];

const Complaints = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { width, height } = useWindowDimensions();

  return (
    <>
      {/* <h1 className="text-orangeBg font-bold text-2xl mb-2">Complaints</h1> */}
      <div
        className={`p-2 flex items-start justify-center gap-2 ${
          width > 600 ? "flex-row" : "flex-col"
        }`}
      >
        <div
          className={`border-x-1 border-slate-300 ${
            width > 600 ? "w-1/4" : "w-full"
          }`}
        >
          <div className="flex items-center justify-center border border-slate-300 rounded">
            <SearchOutlined className="text-slate-300 text-2xl ml-2 w-1/4" />
            {/* <input
              type={"search"}
              placeholder="Search Fleet..."
              className={`${styles.Search}`}
            /> */}
            <input
              className={`${styles.Search} flex-1 block px-4 py-2 rounded-md outline-none`}
              text="text"
              placeholder="Search..."
            />
          </div>
          <div className={`${styles.DisputeList}`}>
            <ul>
              {complaints.map((x, index) => (
                <li
                  className={
                    activeTab === index
                      ? "flex p-3 bg-darkBlue cursor-pointer text-white"
                      : "flex p-3 bg-white cursor-pointer border border-slate-300 text-darkBlue"
                  }
                  key={index}
                  onClick={() => {
                    setActiveTab(index);
                  }}
                >
                  <div className="flex gap-3 flex-1 flex-col">
                    <h1 className="font-bold">{x.name}</h1>
                    <span>{x.car}</span>
                    <h3>{x.time}</h3>
                  </div>
                  <div className="px-3 flex items-end justify-end">
                    <span
                      className={
                        activeTab === index
                          ? "border border-siderbarBg bg-siderbarBg rounded px-4 py-2 text-orangeBg"
                          : "border border-slate-300 bg-slate-300 rounded px-4 py-2 text-darkBlue"
                      }
                    >
                      Dispute
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className={`border border-slate-300 ${
            width > 600 ? "w-3/4" : "w-full"
          } rounded p-2`}
        >
          <DisputeCall />
        </div>
      </div>
    </>
  );
};

export default Complaints;
