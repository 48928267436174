import React from "react";
import { useMutation } from "react-query";
import { approvals } from "../../../api/approval";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

const Approve = ({ type, id, status, handleModal }) => {
  const { mutateAsync } = useMutation(approvals.approveDecline);

  //   console.log(status);

  const { register, handleSubmit } = useForm();

  const handleApprove = async (values) => {
    const { message } = values;
    const payload = { message, status };
    try {
      toast.loading("Processing");
      const data = await mutateAsync({ id, type, payload });
      //   console.log(data);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        handleModal();
        location.reload();
      }, 1000);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleApprove)}>
        <div className="w-full flex items-center justify-center gap-3">
          <div className="flex-1 flex items-start flex-col justify-center gap-1">
            <p>Enter your reason below</p>
            <textarea
              className="w-full flex-1 border border-slate-300 block px-4 py-2 rounded-md outline-none bg-slate-100 duration-200 focus:ring-0 focus:px-5 focus:border-orangeBg focus:bg-white"
              required
              rows={5}
              type={"text"}
              {...register("message", {
                required: true,
              })}
            />
          </div>
        </div>
        <div className="w-full flex items-center justify-start">
          <input
            type="submit"
            value="SUBMIT"
            className="mt-5 px-5 py-2 border border-orangeBg cursor-pointer bg-orangeBg text-white rounded duration-100 hover:text-darkBlue hover:bg-orange-200 hover:border-orange-200"
          />
        </div>
      </form>
    </div>
  );
};

export default Approve;
